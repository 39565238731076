.block.news {
  * {
    box-sizing: border-box;
    font-family: @title;
  }

  .title {
    padding: 128px 32px 32px;
    h1 {
      margin-left: 36px;
      font-size: 3.6rem;
      font-family: @main;
      color: @heading;
      position: relative;
    }
    h1::before {
      content: " ";
      display: block;
      height: 2px;
      background: var(--button-bg-color);
      width: 10rem;
      left: -11rem;
      top: 50%;
      position: absolute;
    }
  }

  .inner {
    .blog-outer {
      display: flex;
      flex-direction: column;
      .blog-container {
        img {
          max-width: 100%;
          height: auto;
          object-fit: contain;
        }
        .card-content {
          padding: 0 2px 100px 2px;
          h2 {
            padding: 0;
            a {
              font-size: 3.2rem;
              color: @heading;
              font-weight: 700;
              text-transform: uppercase;
            }
          }
          .categories {
            font-size: 1.4rem;
            color: @button-text-hover;
            font-weight: 400;
            a {
              font-size: 1.4rem;
              color: @button-text-hover;
              text-transform: capitalize;
              font-weight: 400;
            }
            a:hover {
              color: @text;
            }
          }
          button {
            border: none;
            background: none;
            font-size: 1.6rem;
            color: @text;
            font-weight: 700;
            padding: 0;
            text-transform: uppercase;
            &::after {
              content: " →";
              display: inline-block;
              color: @button-bg;
            }
          }
          p {
            a {
              time {
                font-size: 1.4rem;
                color: @text;
                font-weight: 400;
              }
            }
          }
        }
      }
      .blog-navigation {
        display: flex;
        width: 100%;
        height: 2.8rem;
        padding: 20px 0 50px;
        position: relative;
        a {
          font-size: 1.6rem;
          color: @text-link-hover;
          font-weight: 700;
          position: absolute;
        }
        .prev {
          left: 0;
          margin-left: 2px;
        }
        .next {
          right: 0;
          margin-right: 2px;
        }
      }
    }
    .filters {
      color: @text;
      padding: 0 2px;
      div {
        padding-bottom: 30px;
        h2 {
          font-size: 1.8rem;
          text-transform: uppercase;
          font-weight: 700;
        }
        ul {
          list-style: none;
          li {
            a {
              font-size: 1.6rem;
              color: @text;
            }
            a:hover {
              color: @text-link-hover;
            }
            padding: 5px 0;
          }
        }
      }
    }
  }

  .tablet({
    .title {
      h1 {
        margin-left: 256px;
      }
      h1::before {
        width: 260px;
        left: -30rem;
      }
    }
    .inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 40px;
      .blog-outer {
        width: 70%;
      }
      .filters {
        width: 25%;
      }
    }
  });

  .desktop({});
}
