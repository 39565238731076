.block.map-header {
  position: relative;
  padding: 20px 0 0;
  .content {
    position: absolute;
    top: 10rem;
    padding: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    h1 {
      padding-left: 40px;
      margin-bottom: 10px;
    }
    h1::before {
      content: " ";
      display: block;
      height: 2px;
      background: @button-bg;
      width: 45px;
      left: 0;
      top: 30px;
      position: absolute;
    }
    select {
      width: 100%;
      margin-bottom: 10px;
      &.state-select {
        display: block;
      }
    }
  }

  .tablet({
    padding: 0;
    .content {
      position: absolute;
      h1 {
        padding-left: 265px;
      }
      h1::before {
        width: 270px;
        left: 0;
        top: 30px;
      }
    }
  });

  .desktop({
    .content {
      flex-direction: row;
      justify-content: space-between;
      top: 10rem;
      select {
        width: 18rem;
        &.state-select {
          display: none;
        }
      }
    }
  });
}