.block.contact-us {
  * {
    box-sizing: border-box;
  }

  .title {
    padding: 128px 32px 32px;
    h1 {
      margin-left: 36px;
      font-size: 3.6rem;
      color: @heading;
      position: relative;
    }
    h1::before {
      content: " ";
      display: block;
      height: 2px;
      background: @button-bg;
      width: 10rem;
      left: -11rem;
      top: 50%;
      position: absolute;
    }
  }

  .inner {
    padding: 48px 24px;
    .content {
      .contact-info {
        .address, .hours, .fax {
          margin-bottom: 20px;
        }
        h2,
        h3,
        h4,
        h5 {
          color: @heading-alt;
          text-transform: uppercase;
          font-size: 2rem;
          font-family: @main;
          font-weight: 700;
          padding: 0;
        }
        p {
          color: @text;
          font-size: 1.8rem;
          font-weight: 400;
        }
        a {
          color: @text;
          font-size: 1.8rem;
          font-weight: 400;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .phone {
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .phone,
        .fax {
          display: flex;
          flex-direction: row;
          gap: 10px;
          div {
            margin-bottom: 0;
          }
          h2, h3, h4, h5 {
            margin-bottom: 0;
          }
        }
      }

      form {
        .form-group:not(:last-child) {
          padding-bottom: 22px;
        }
        .form-group:last-child {
          padding-bottom: 10px;
        }
        input,
        select {
          display: block;
          padding: 0;
          text-indent: 15px;
          height: 60px;
          border: none;
          border-radius: 0;
          font-size: 1.7rem;
          background: #e6e6e6;
          font-weight: 700;
          &::placeholder {
            color: @text;
          }
        }
        label {
          // Hide label but keep it accessible
          position: absolute;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
        }
        textarea {
          height: 120px;
          max-height: 350px;
          padding: 18px;
          background-color: #e6e6e6;
          border: none;
          border-radius: 0;
          font-size: 1.7rem;
          font-weight: 700;
          resize: vertical;
          &::placeholder {
            color: @text;
          }
        }
      }
      .disclaimer-wrapper {
        visibility: hidden;
        width: 100%;
        padding-bottom: 10px;
        .disclaimer {
          max-width: 500px;
          padding: 0;
          * {
            font-size: 1.4rem;
          }
        }
        &.moved {
          visibility: visible;
        }
      }
    }
  }



  .tablet({
    .title {
      h1 {
        margin-left: 256px;
      }
      h1::before {
        width: 200px;
        left: -290px;
      }
    }
    .inner {
      padding: 48px 24px;
      .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        aside {
          width: 25%;
        }
        .form {
          max-width: 70%;
          form {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }
  });


  &.iframe-embed {
    .inner {
      padding: 24px 0;
      .content {
        .contact-info {
          padding: 0 24px;
        }
      }
    }
    // iframe {
    //   height: 110rem !important;
    //   min-height: 1000px;
    // }
    .tablet({
      // iframe {
      //   min-height: 90rem !important;
      // }
      .inner {
        .content {
          .contact-info {
            padding: 45px 24px;
            width: 30%;
          }
        }
      }
      .embed-outer {
        width: 70%;
      }
    });

    .vp(1098px, {
      .inner {
        padding: 0;
        .content {
          .contact-info {
            padding: 114px 24px;
            width: 30%;
          }
        }
      }
    })
  }
}


#rs-builder {
  .disclaimer-wrapper {
    visibility: visible !important;
  }
}