.block.topbar {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  * {
    box-sizing: border-box;
  }

  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: @page-bg;

  .skip-link {
    position: absolute;
    left: -9999px;
    transition: all 0s ease-out;
  }

  .skip-link:focus {
    position: absolute;
    color: @text;
    background-color: @page-bg;
    left: 16px;
    top: 16px;
    width: auto;
    height: auto;
    overflow: auto;
    padding: 5px;
    border: 4px solid rgb(0, 60, 255);
    text-align: center;
    font-weight: 900;
    font-size: 1.2em;
    z-index: 999;
    box-shadow: 0 0 0 4px #9a161c, 0 1px 10px 0 rgba(0, 0, 0, 0.5) !important;
  }

  .inner {
    display: flex;
    flex-direction: row;
    .logo {
      flex: 1;
      padding: 16px 12px;
      img {
        height: auto;
        max-width: 130px;
      }
    }
    .main-nav {
      display: none;
    }
    .mobile-menu {
      padding: 16px 12px;
      button {
        background-color: transparent;
        border: none;
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 6px 0;
        justify-content: space-evenly;
        span {
          background-color: @text;
          height: 3px;
          align-self: end;
        }
        span:nth-child(1) {
          width: 60%;
        }
        span:nth-child(2) {
          width: 80%;
        }
      }
    }

    .mobile-menu-overlay {
      position: fixed;
      overflow-y: auto;
      transform: translateX(100%);
      opacity: 0;
      transition: all 0.2s ease-in;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #242424fa;
      z-index: 999;
      padding: 16px 32px;
      .overlay-top {
        display: flex;
        flex-direction: row;
        width: 100%;
        .logo-wrapper {
          width: 50%;
          a {
            margin-bottom: 16px;
            img {
              max-width: 100px;
            }
          }
        }
        .close-wrapper {
          width: 50%;
          display: flex;
          justify-content: end;
          button {
            background-color: transparent;
            border: none;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            cursor: pointer;
            margin: 0;
          }
        }
      }
      ul {
        width: 100%;
        margin-top: 80px;
        li {
          line-height: 1.5;
          text-align: center;
          font-weight: 700;
          font-family: @title;
          text-transform: uppercase;
          button {
            background-color: transparent;
            border: none;
            font-size: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            i {
              font-size: 2rem;
              margin-left: 8px;
              pointer-events: none;
            }
          }
          .dropdown {
            display: none;
            flex-direction: column;
            a {
              color: @button-text-hover;
            }
            a:hover,
            a:focus {
              color: @button-bg;
            }
          }
          a {
            color: @text-alt;
            font-size: 3.2rem;
            font-weight: 700;
            font-family: @main;
            text-transform: uppercase;
            i {
              font-size: 2rem;
              margin-left: 8px;
            }
          }
          &.clicked .dropdown {
            display: block;
          }

        }
      }
    }

    .mobile-menu-overlay:not(.hidden) {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .desktop({
    .inner {
    .mobile-menu {
      display: none;
    }
    .mobile-menu-overlay {
      display: none;
    }
    .main-nav {
      flex: 1.5;
      display: flex;
      align-items: center;
      justify-content: end;
      ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          .nav-dropdown {
            border: none;
            background-color: transparent;
            padding: 8px 12px;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            display: inline-block;
            span, p {
              font-size: 1.6rem;
              font-weight: 700;
              color: @heading;
              text-transform: uppercase;
            }
          }
          a {
            color: inherit;
            text-decoration: none;
            padding: 8px 12px;
            text-transform: uppercase;
            border-bottom: 2px solid transparent;
            font-weight: 700;
            cursor: pointer;
            display: block;
          }
          .dropdown {
            display: none;
          }
        }
          li:focus-within .dropdown, .hovered .dropdown {
          display: flex;
          flex-direction: column;
          position: absolute;
          background-color: @page-bg;
          box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
          a, button {
            font-size: 1.6rem;
            color: @button-text-hover;
          }
          a:hover, a:focus, button:hover, button:focus {
            color: @button-bg;
          }
        }
        a.active, .nav-dropdown.active {
          border-bottom: 2px solid @heading-alt;
          color: @heading-alt;
        }
        a:hover, a:focus, .nav-dropdown:hover span, .nav-dropdown:focus span, .nav-dropdown.active span {
          color: @heading-alt;
        }
      }
    }
  }
});


.inner {
  .main-nav {
    .loading {
      display: none;
    }
  }
}
}
