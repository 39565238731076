.block.sitemap {
  .inner {
    padding: 48px 24px;
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: @main;
    }
    h1 {
      font-size: 3.2rem;
      margin: 32px 0 16px;
    }
    nav {
      text-transform: uppercase;
      padding-top: 50px;
      h2,
      h3,
      h4,
      h5 {
        font-size: 2.8rem;
        margin-bottom: 16px;
        padding: 0;
      }
      ul {
        margin: 16px 0 0 32px;
        list-style: circle;
        list-style-position: inside;
        li {
          font-size: 1.6rem;
          padding: 5px 12px;
          .editable {
            display: inline;
            & * {
              display: inline;
            }
          }
          a {
            color: @text;
            font-weight: 700;
          }
          a:hover {
            color: @text-link-hover;
          }
        }
      }
    }
  }
}
