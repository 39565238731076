.block.services {
  padding: 120px 24px 80px 24px;

  .inner {
    .card.in-view,
    .left-title.in-view {
      animation: forwards 0.8s servicesFadeIn 0.4s;
    }
    .left-title {
      transform: rotate(-90deg);
      transform-origin: bottom left;
      position: absolute;
      top: 22rem;
      left: 5rem;
      z-index: 9;
      & .in-view {
        animation: forwards 0.8s servicesFadeIn 0.4s;
      }
      h2,
      h3,
      h4,
      h5 {
        font-size: 1.4rem;
        margin-bottom: 20px;
        letter-spacing: 2px;
      }
    }
    .left-title::after {
      content: " ";
      display: block;
      height: 2px;
      width: 12rem;
      position: absolute;
      right: -13rem;
      top: calc(50% - 2rem);
      background-color: @heading-alt;
    }

    .card-container {
      margin-left: 3.5rem;
      .card {
        margin-top: 40px;
        display: flex;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          height: 80px;
          width: 80px;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        h2,
        h3,
        h4,
        h5 {
          margin: 15px 0 20px 0;
          font-size: 1.8rem;
          text-transform: uppercase;
          text-align: center;
          font-family: @main;
        }
        p {
          text-align: center;
        }
      }
    }

    .link-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3rem 0 1.5rem 0;
    }
  }

  .tablet({
    .inner {
      .left-title {
        h2, h3, h4, h5 {
          font-size: 3rem;
        }
      }
      .left-title::after {
        content: " ";
        display: block;
        height: 2px;
        width: 14.5rem;
        position: absolute;
        right: -16rem;
        top: calc(50% - 2rem);
        background-color:@heading-alt;
      }
      .card-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: start;
        margin-left: 1.5rem;
        padding-left: 68px;
        gap: 68px;
        .card {
          width: calc(33.3333% - 45.33333px);
          margin-top: 48px;
          padding: 12px;
        }
        .card.in-view {
          animation: forwards 0.8s servicesFadeIn 0.4s;
        }
        .card:nth-child(1) {
          animation-delay: 0.4s;
        }
        .card:nth-child(2) {
          animation-delay: 0.5s;
        }
        .card:nth-child(3) {
          animation-delay: 0.6s;
        }
        .card:nth-child(4) {
          animation-delay: 0.7s;
        }
        .card:nth-child(5) {
          animation-delay: 0.8s;
        }
        .card:nth-child(6) {
          animation-delay: 0.9s;
        }
      }
    }
	});
}

@keyframes servicesFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
