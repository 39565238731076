.block.footer {
  * {
    box-sizing: border-box;
    color: @text-alt;
  }
  background-color: @primary-bg;

  p,
  h2,
  a,
  span {
    font-family: @main;
    line-height: 1.5;
  }

  .footer-nav-1 a,
  .footer-nav-2 a,
  h2,
  strong {
    text-transform: uppercase;
  }

  p,
  a,
  span {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    padding: 0;
  }

  li {
    line-height: 1.5;
  }

  .content li a {
    font-weight: 700;
  }

  .content li a:hover {
    opacity: 0.6;
  }

  .inner {
    padding: 48px 24px 32px;
    .content {
      display: flex;
      flex-direction: column;
      > * {
        padding: 12px;
        opacity: 1;
      }
    }
    .footer-logo {
      img {
        width: 100%;
        max-width: 140px;
      }
    }
    .footer-address a {
      text-transform: capitalize;
    }
    .footer-address a:hover {
      opacity: 0.6;
    }
    .footer-contact .phone a:hover {
      opacity: 0.6;
    }
    .footer-nav-1 {
      padding-bottom: 0;
    }
    .footer-nav-2 {
      border-bottom: 1px solid @text-alt;
      margin-bottom: 20px;
      padding-top: 0;
    }
    .socials {
      margin-top: 2px;
      display: flex;
      justify-content: left;
      align-items: center;
      a {
        padding-right: 10px;
        svg {
          path {
            fill: @text-alt;
          }
        }
      }
      a:hover {
        opacity: 0.6;
      }
    }
  }
  .legal {
    margin-top: 48px;
    color: @secondary-bg;
    opacity: 1;
    ul {
      list-style: none;
      li {
        padding-bottom: 10px;
        opacity: 0.8;
      }
      li:hover:not(:first-child) a {
        opacity: 0.6;
      }
    }
  }

  &.in-view {
    .content {
      > * {
        animation-name: footerFadeIn;
        animation-fill-mode: forwards;
        animation-duration: 0.8s;
        &:nth-child(1) {
          animation-delay: 300ms;
        }
        &:nth-child(2) {
          animation-delay: 400ms;
        }
        &:nth-child(3) {
          animation-delay: 500ms;
        }
        &:nth-child(4) {
          animation-delay: 600ms;
        }
        &:nth-child(5) {
          animation-delay: 700ms;
        }
      }
    }
    .legal {
      animation-name: footerFadeIn;
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      animation-delay: 800ms;
    }
  }

  .desktop({
		.inner {
			.content {
				flex-direction: row;
				> * {
					width: calc(100% / 5);
				}
				.footer-nav-1 {
					padding-bottom: 12px;
				}
				.footer-nav-2 {
					padding-top: 12px;
					border-bottom: none;
					border-right: 1px solid @text-alt;
					margin: 0 40px 20px 0;
				}
			}
			.legal {
				ul {
					display: flex;
					flex-direction: row;
					justify-content: center;
					li {
						padding-bottom: 0;
						padding-right: 10px;
						margin-right: 10px;
						border-right: 1px solid @secondary-bg;
					}
					li:nth-child(5) {
						border-right: none;
					}
				}
			}
		}
});
}

@keyframes footerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
