html {
  font-size: 62.5%;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

body {
  font-size: 1.7rem;
  line-height: 1.75;
  color: @text;
  font-family: @main;
}

article.text-content {
  padding: 96px 24px 48px;
}

img {
  display: block;
}

#mainwrap {
  overflow: hidden;
}

.inner {
  position: relative;
  margin: auto;
  max-width: 1344px;
  width: 100%;
}


#cookie-box {
  background-color: #242424 !important;
  border-top: 1px solid #fff;
  padding: 16px 24px;
  #cookie-box-inner {
    flex-direction: column;
    align-items: start;
    &::before {
      content: 'Cookie Policy';
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }
    p {
      line-height: 1.5;
      text-align: left;
      font-size: 1.6rem !important;
      margin: 0 0 16px !important;
    }
    #cookie-button {
      background: #d0d0d0;
      padding: 8px;
      color: #242424;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px ;
      width: fit-content !important;
      border-radius: 4px;
      margin: 0;
    }
  }

  .tablet({
    width: 30% !important;
    #cookie-box-inner {
      #cookie-button {
        padding: 12px;
        font-size: 18px;
      }
    }
  });
}