.block.mission-statement {
  padding: 80px 32px;

  .builder {
    .video-link {
      display: flex !important;
      align-items: center;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      svg {
        height: 50px;
        width: 50px;
        fill: red;
      }
      span {
        color: red !important;
        font-weight: 700 !important;
      }
    }
    &.video-wrapper {
      background: linear-gradient(
        15deg,
        rgba(34, 34, 34, 0.8),
        transparent 65%
      ) !important;
    }
  }

  .inner {
    position: relative;
    width: 100%;
    .left-title {
      transform: rotate(-90deg);
      transform-origin: bottom left;
      position: absolute;
      top: 25rem;
      left: 5rem;
      z-index: 9;
      h2,
      h3,
      h4,
      h5 {
        font-size: 1.4rem;
        margin-bottom: 20px;
        padding-bottom: 15px;
        letter-spacing: 2px;
      }
    }
    .left-title.in-view {
      animation: forwards 0.8s missionFadeIn 0.4s;
    }
    .left-title::after {
      content: " ";
      display: block;
      height: 2px;
      width: 12rem;
      position: absolute;
      right: -13rem;
      top: calc(50% - 2rem);
      background-color: @heading-alt;
    }
    .content {
      display: flex;
      flex-direction: column;

      .main-content {
        padding: 12px;
        margin: 0 0 0 35px;
        h2,
        h3,
        h4,
        h5 {
          text-transform: uppercase;
          font-size: 3.2rem;
          font-family: @main;
        }
        p {
          margin-top: 34px;
        }
        .video-wrapper {
          display: none;
        }
      }
      .main-content.in-view {
        animation: forwards 0.8s missionFadeIn 0.4s;
      }
      .read-more {
        width: 100%;
        padding: 30px 0;
        h2,
        h3,
        h4,
        h5 {
          text-transform: uppercase;
          font-family: @main;
        }
        a {
          font-size: 1.6rem;
          color: @text;
          text-transform: uppercase;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .read-more.in-view {
        animation: forwards 0.8s missionFadeIn 0.4s;
      }
    }
    .button-wrapper {
      .btn {
        width: 100%;
        margin-bottom: 10px;
        border: none;
      }
      .button {
        margin: 2px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        div {
          width: 100%;
          height: 100%;
        }
        a {
          padding: 12px 24px;
          display: block;
          color: @button-text;
          width: 100%;
          height: 100%;
        }
      }
      .button:hover {
        background-color: @button-bg-hover;
      }
    }
    .button-wrapper.in-view {
      animation: forwards 0.8s missionFadeInAndSlide 0.4s;
    }
  }

  .tablet({
    .inner {
      .left-title {
        h2, h3, h4, h5 {
          font-size: 3rem;
        }
      }
      .left-title::after {
        content: " ";
        display: block;
        height: 2px;
        width: 14.5rem;
        position: absolute;
        right: -16rem;
        top: calc(50% - 2rem);
        background-color:@heading-alt;
      }
      .content {
        display: flex;
        flex-direction: row;
        .main-content {
          position: relative;
          width: 70%;
          p {
            margin-bottom: 32px;
          }
          .video-wrapper {
            position: relative;
            display: block;
            height: 315px;
            width: 560px;
            margin-bottom: 32px;
            iframe, video {
              height: 315px;
              width: 560px;
            }
          }
        }
        .read-more {
          width: 30%;
          padding: 12px;
        }
      }
      .button-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .btn {
          flex: 1;
          min-width: fit-content;
          margin-right: 5px;
        }
      }
    }
  });

  .desktop({
    .inner {
      .button-wrapper {
        margin-left: 35px;
        padding-left: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .btn {
          flex: unset;
          width: fit-content;
          margin-right: 5px;
        }
      }
    }
  });
}

@keyframes missionFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes missionFadeInAndSlide {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
