.block.brands {
  .images-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    .brand-image {
      position: relative;
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      transition: ease all .3s;
    }
    .brand-logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      transition: ease all .3s;
    }
    .logo {
      opacity: 0;
      transition: ease opacity .3s;
      width: 80%;
    }
    .description {
      display: none;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      width: 1px;
      height: 1px;
    }
  }

  .content {
    background-color: #D0D0D0;
    min-height: 200px;
    padding: 20px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .image {
      width: 200px;
      margin-bottom: 30px;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
    .title {
      * {
        font-size: 3.6rem;
        color: @heading-alt;
      }
    }
  }
  .content-card {
    display: none;
    opacity: 0;
    transition: ease all .3s;
    &.active {
      display: block;
      opacity: 1;
    }
  }

  .inner:has(.opened) {
    .brand-image:not(.opened) {
      img {
        filter: grayscale(100%);
      }
    }
  }

  .brand-image:hover, .brand-image:focus {
    outline: none;
    .brand-logo {
      background-color: rgba(0, 0, 0, .2);
    }
    .logo {
      opacity: 1;
    }
  }

  .desktop({
    .inner {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .images-container {
        width: 60%;
        height: 380px;
        display: flex;
        flex-direction: row;
        gap: 2px;
        .brand-image {
          flex: 1;
          img:not(.logo) {
            height: 100%;
          }
        }
      }
      .content {
        width: 40%;
        margin: 20px 0;
      }
    }
  });

  &.builder {
    .images-container {
      height: unset;
      min-height: 380px;
      img {
        height: 200px;
      }
    }
    .brand-image {
      height: fit-content;
    }
    .brand-logo {
      position: relative;
      background-color: #ddd;
      flex-direction: column;
      padding: 15px;
      pointer-events: all;
    }
    .logo {
      opacity: 1;
      height: auto;
      width: 100%;
      object-fit: contain;
      margin: 20px 0;
    }
    .description {
      display: block;
      width: 100%;
      height: 100%;
      color: #FFF;
      position: relative;
    }
  }
}