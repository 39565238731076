.block.header {
  .image-bg {
    display: none;
  }
  * {
    box-sizing: border-box;
  }
  position: relative;
  max-height: 100%;
  height: 90vh;
  min-height: 600px;
  display: flex;
  justify-content: flex-end;
  z-index: 0;

  &.builder {
    // Styling specifically for IO builder
    height: fit-content;
    .content {
      top: 0 !important;
    }
  }

  .content {
    position: absolute;
    top: 20%;
    left: 0;
    padding: 24px;
    width: 100%;
    height: fit-content;
    background: #000000ba;
    height: fit-content;
    z-index: 10;
    p {
      font-size: 1.8rem;
    }
    p,
    h2,
    h3,
    h4,
    h5 {
      text-align: left;
      margin: 0;
      padding: 0;
      animation-name: contentFadeIn;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-delay: 0.3s;
      opacity: 0;
    }
    .button {
      animation-name: contentFadeIn;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-delay: 0.3s;
      opacity: 0;
      position: absolute;
      right: 0;
      bottom: -25px;
      padding: 8px;
      border: none;
      pointer-events: all;
    }
  }

  .owl-carousel {
    position: relative;
    height: 100%;
    .owl-stage-outer {
      height: 100%;
      .owl-stage {
        height: 100%;
        .owl-item {
          width: 67%;
          height: 100%;
          .item {
            height: 100%;
            img {
              object-fit: cover;
              object-position: center;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .overlay {
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: left;
    padding: 14px;
    background: #000000ba;
    pointer-events: none;
    p {
      font-size: 1.8rem;
      margin: 0;
      padding: 0;
    }
  }

  .tablet({
		.content {
			width: 510px;
      padding: 48px;
      .button {
        font-size: 1.8rem;
        padding: 12.8px;
      }
		}
		.overlay {
			width: 500px;
			bottom: 10%;
		}
});
}

// Keyframes
@keyframes contentFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}