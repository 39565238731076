.block.about-header {
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }

  .image-bg {
    position: relative;
    height: 78vh;
    width: 100%;
    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .video-wrapper {
    display: none;
    position: relative;
    height: 100vh;
    margin-top: -20px;
    object-fit: cover;
    overflow: hidden;
    .video-link {
      display: none;
    }
    .image-bg {
      height: 100%;
      width: 100%;
      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    iframe,
    video {
      // height: 125vh;
      width: calc((100vh * 16) / 9);
      min-width: 100%;
      pointer-events: none;
    }
  }
  .video-wrapper::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(15deg, rgba(34, 34, 34, 0.8), transparent 65%);
  }
  .content-wrapper {
    width: 100%;
    &.in-view {
      animation-name: aboutHeaderFadeIn;
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      animation-delay: 0.4s;
    }
    .inner {
      position: relative;
      .left-title {
        transform: rotate(-90deg);
        transform-origin: bottom left;
        position: absolute;
        top: 20rem;
        left: 5rem;
        z-index: 9;
        h2,
        h3,
        h4,
        h5 {
          font-size: 1.4rem;
          letter-spacing: 2px;
        }
      }
      .left-title::after {
        content: " ";
        display: block;
        height: 2px;
        background: @heading-alt;
        width: 12rem;
        position: absolute;
        right: -13rem;
        top: calc(50% - 10px);
      }

      .text-container {
        width: 100%;
        position: relative;
        margin-top: -100px;
        .text-wrapper {
          width: 100%;
          background-color: @page-bg;
          padding: 32px 32px 64px 64px;
          .text {
            margin-bottom: 20px;
            p {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }

  .tablet({
    .mobile {
      display: none;
    }
		.video-wrapper {
			height: 78vh;
      display: block;
			iframe,
      video {
        height: 100vh;
			}
		}
    .content-wrapper {
      padding: 0 24px;
      .inner {
        .left-title {
          left: 3rem;
          h2 {
            font-size: 3rem;
          }
        }
        .left-title::after {
          width: 14.5rem;
          right: -16rem;
          top: calc(50% - 10px);
        }
        .text-container {
          margin-left: -24px;
          .text-wrapper {
            width: 80%;
            padding: 32px 32px 64px 128px;
            p {
              font-size: 1.8rem;
            }
          }
        }
      }
    }

});

  .desktop({
  .video-wrapper {
    height: 78vh;
    iframe,
      video {
      height: 100vh;
      min-height: 100%;
      width: calc((100vh* 16) / 9);
    }
  }
});

  .ultra({
    .video-wrapper {
      iframe,
        video {
        width: 100%;
        height: calc((100vw* 9) / 16);
      }
    }
  });
}

@keyframes aboutHeaderFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


#rs-builder {
  .rs-rs-bar {
    z-index: 3;
  }
  .block.about-header {
  .video-link, .image-bg {
    display: flex !important;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    // z-index: 1;
    color: red;
    font-weight: 700;
    svg {
      height: 50px;
      width: 50px;
      fill: red;
    }
    span {
      color: red !important;
      font-weight: 700 !important;
    }
  }
  .image-bg {
    top: 40px;
    height: 100px !important;
    width: 100px !important;
    z-index: 2;
    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
}