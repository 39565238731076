.block.news-single {
  * {
    box-sizing: border-box;
    font-family: @title;
    line-height: 1.5;
  }

  .title {
    padding: 128px 32px 32px;
    h1 {
      margin-left: 36px;
      font-size: 3.6rem;
      font-family: @main;
      color: @heading;
      position: relative;
    }
    h1::before {
      content: " ";
      display: block;
      height: 2px;
      background: var(--button-bg-color);
      width: 10rem;
      left: -11rem;
      top: 50%;
      position: absolute;
    }
  }

  .inner {
    .blog-outer-single {
      display: flex;
      flex-direction: column;
      .blog-single {
        .blog-image {
          width: 100%;
          img {
            max-height: 400px;
            max-width: 100%;
            object-fit: cover;
            object-position: center 40%;
          }
        }
        .blog-title {
          padding: 0 2px;
          h2 {
            padding: 0;
            font-size: 3.2rem;
            color: @heading;
            text-transform: capitalize;
          }
        }
        .blog-datestamp {
          padding: 0 2px;
          font-size: 1.4rem;
          color: @text;
        }
        .blog-content {
          padding: 0 2px;
          padding-top: 24px;
          ol,
          ul {
            padding-bottom: 24px;
          }
          li {
            list-style-position: inside;
            list-style: none;
          }
          p {
            padding-bottom: 24px;
            font-size: 1.6rem;
          }
          a {
            color: @text-link-hover;
            &:hover {
              color: @text;
            }
          }
        }
        .divider {
          background-color: @text;
          height: 2px;
          width: 100%;
          margin: 24px 0;
        }
        .blog-categories {
          padding: 0 2px;
          padding-bottom: 50px;
          font-size: 1.4rem;
          a {
            color: @text-link-hover;
            text-transform: uppercase;
            &:hover {
              color: @text;
            }
          }
          span {
            color: @text;
          }
        }
      }
      .filters {
        color: @text;
        padding: 0 2px;
        div {
          padding-bottom: 30px;
          h2 {
            font-size: 1.8rem;
            text-transform: uppercase;
            font-weight: 700;
          }
          ul {
            list-style: none;
            li {
              a {
                font-size: 1.6rem;
                color: @text;
              }
              a:hover {
                color: @text-link-hover;
              }
              padding: 5px 0;
            }
          }
        }
      }
    }
  }

  .tablet({
    .title {
      h1 {
        margin-left: 256px;
      }
      h1::before {
        width: 260px;
        left: -30rem;
      }
    }
		.inner {
      .blog-outer-single {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
      margin: 0 24px;
			.blog-single {
				width: 70%;
			}
			.filters {
				width: 25%;
			}
		}
  }
});
}
