.block.terms {
  .inner {
    padding: 200px 0;
    .content {
      margin: 0 24px;
      .title {
        margin-bottom: 8px;
      }
      .section {
        margin: 32px 0 16px;
      }
      .subsection {
        margin: 0 0 16px;
      }
      .text {
        p {
          margin-bottom: 16px;
        }
        i,
        em {
          font-family: @title;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: @main;
        padding: 0;
      }

      h3 {
        font-size: 2.4rem;
        font-weight: 700;
      }

      h4 {
        font-size: 2rem;
        font-weight: 700;
        margin: 0 0 16px;
      }
    }
    a:hover {
      color: @text-link-hover;
    }
    ul {
      margin: 16px 0 16px 32px;
      li {
        .editable {
          display: inline;
          & * {
            display: inline;
          }
        }
      }
    }
  }
}
