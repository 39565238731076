.block.map-key {
  position: relative;
  .key {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;
    position: absolute;
    top: -130px;
    span {
      height: 18px;
      width: 18px;
      margin-right: 8px;
      display: inline-block;
    }
    p {
      display: inline-block;
    }
    .current {
      span {
        background: #242424;
      }
    }
    .previous {
      span {
        background: #777777;
      }
    }
    .current, .previous {
      display: flex;
      align-items: center;
    }
  }
}