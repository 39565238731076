.block.benefits {
  margin-bottom: 80px;

  .inner {
    .benefits-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 24px 80px;
      .benefit {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 48px;
        padding: 12px;
        .icon {
          height: 60px;
          width: 60px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        h2,
        h3,
        h4,
        h5 {
          margin: 15px 0 20px;
          text-transform: uppercase;
          font-family: @main;
        }
      }
      .benefit.in-view {
        animation: benefitFadeIn 0.5s ease-in-out 0.4s forwards;
      }
    }
  }
  .disclaimer-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
    padding: 0 4px;
    .text {
      margin-bottom: 16px;
      * {
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  .tablet({
    .inner {
      .benefits-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        .benefit {
          width: 30%;
        }
        .benefit.in-view {
          animation: benefitFadeIn 0.5s ease-in-out 0s forwards;
        }

        .benefit:nth-child(1) {
          animation-delay: 0.4s;
        }
        .benefit:nth-child(2) {
          animation-delay: 0.5s;
        }
        .benefit:nth-child(3) {
          animation-delay: 0.6s;
        }
        .benefit:nth-child(4) {
          animation-delay: 0.7s;
        }
        .benefit:nth-child(5) {
          animation-delay: 0.8s;
        }
        .benefit:nth-child(6) {
          animation-delay: 0.9s;
        }
      }
    }
  });

  .desktop({});
}

@keyframes benefitFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
