.block.testimonials {
  p,
  li,
  span {
    color: @text;
  }
  .title {
    padding: 128px 32px 32px;
    h1 {
      position: relative;
    }
    h1::before {
      content: " ";
      display: block;
      height: 2px;
      background: @button-bg;
      width: 10rem;
      left: -10rem;
      top: 50%;
      position: absolute;
    }
  }

  .title.in-view {
    animation: forwards 0.8s testimonialReverseFadeInSlide 0.4s;
  }

  .inner {
    .desc {
      padding: 48px 32px;
      p {
        font-size: 1.8rem;
      }
      &.in-view {
        animation: forwards 0.8s testimonialFadeIn 0.4s;
      }
    }
    .content {
      .testimonial {
        &:nth-child(even) .img-wrapper {
          &.in-view {
            animation: forwards 0.8s testimonialFadeInSlide 0.4s;
          }
        }
        &:nth-child(odd) .img-wrapper {
          &.in-view {
            animation: forwards 0.8s testimonialReverseFadeInSlide 0.4s;
          }
        }
        .testimonial-container {
          .timeline {
            display: flex;
            flex-direction: column;
            min-height: 700px;
            text-align: center;
            &.in-view {
              animation: forwards 0.8s testimonialFadeIn 0.4s;
            }
            h2,
            h3,
            h4,
            h5 {
              font-size: 3.5rem;
              font-weight: 700;
              text-transform: capitalize;
              font-family: @title;
              padding: 0;
            }
            ul {
              list-style: none;
              padding: 0 12px 12px;
              li {
                margin-bottom: 20px;
                padding: 0 2px;
                .position {
                  position: relative;
                  h2,
                  h3,
                  h4,
                  h5 {
                    text-transform: uppercase;
                    font-size: 1.8rem;
                    display: inline-block;
                    font-family: @main;
                  }
                }
                .position::before {
                  content: " ";
                  display: inline-block;
                  height: 12px;
                  width: 12px;
                  background: @button-bg;
                  margin-right: 4px;
                }
                .details {
                  font-weight: 400;
                  font-size: 1.8rem;
                }
              }
            }
          }
          .img-wrapper {
            height: 400px;
            width: 100%;

            img {
              height: 100%;
              width: 100%;
              object-position: top;
              object-fit: cover;
            }
          }
        }
        .quote {
          padding: 0 32px 96px;
          margin-bottom: 40px;
          &.in-view {
            animation: forwards 0.8s testimonialFadeIn 0.4s;
          }
          .quote-wrap {
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-color: @secondary-bg;
            padding: 48px;
            .start {
              width: 50px;
              position: absolute;
              top: -15px;
              left: 3rem;
            }
            p {
              font-size: 1.8rem;
              font-weight: 700;
            }
            .end {
              width: 50px;
              right: 3rem;
              position: absolute;
              transform: rotate(180deg);
              bottom: -15px;
            }
          }
        }
      }
    }
  }

  .tablet({
    .title {
      h1 {
        margin-left: 256px;
        &::before {
          width: 200px;
          left: -290px;
        }
      }
    }
    .inner {
      .content {
        line-height: 1.5;

        .testimonial {
          .testimonial-container {
            display: flex;
            flex-direction: row;
            min-height: 700px;
            .timeline {
              padding: 12px 48px 12px 12px;
              flex: 1;
              text-align: end;
              .employee {
                padding: 0;
                margin-bottom: 20px;
                h2 {
                  padding: 0;
                }
              }
              ul {
                position: relative;
                padding-top: 5px;
                  li {
                    margin: 0 20px 20px 0;
                  }
                .position::before {
                  margin-right: 0 !important;
                  position: absolute;
                  right: -26px;
                  top: 5px;
                }
                &::after {
                  content: " ";
                  display: block;
                  height: 110%;
                  min-height: 300px;
                  background: @button-bg;
                  width: 2px;
                  position: absolute;
                  top: 0;
                  right: 14px;
                }
              }
            }
            .img-wrapper {
              flex: 1;
              height: 700px;
            }
          }
          &:nth-child(even) .testimonial-container{
            flex-direction: row-reverse;
            .timeline {
              flex: 1;
              text-align: start;
              ul {
                text-align: start;
                padding: 5px 0 0 48px;
                  li {
                    margin: 0 0 20px 20px;
                  }
                .position::before {
                  left: -26px;
                }
                &::after {
                  left: 50px;
                }
              }
            }
          }
        }
      }
    }
  });

  .desktop({
    .inner {
      .content {
        .testimonial {
          .quote {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  });
}

@keyframes testimonialFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes testimonialFadeInSlide {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes testimonialReverseFadeInSlide {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
