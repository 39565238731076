.block.career-header {
  * {
    box-sizing: border-box;
  }

  position: relative;
  height: 50vh;
  min-height: 600px;

  &.builder {
    height: fit-content;
  }

  #career-carousel {
    position: relative;
    height: 100%;
    .owl-stage-outer {
      height: 100%;
      .owl-stage {
        height: 100%;
        .owl-item {
          height: 100%;
          .item {
            height: 100%;
            img {
              object-fit: cover;
              object-position: center;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
