.block.pmap{
	padding:  300px 20px 150px;
	a{
		color: var(--heading-color-alt);
	}

	#stats{
		position: absolute;
		display: none;
		width: 130px;
		padding: 6px;
		font-size: 11px;
		line-height: 15px;
		background: #fff;
		border: 1px solid #333;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
		text-align: center;
		color: #111;
		z-index: 1000000;
		text-align: left;

		> strong{
			text-transform: uppercase;
		}
	}

	#simple-map{
		position: relative;
		max-width: 900px;
		margin: auto;
	}

	#us-map{
		position: relative;
		width: 100%;
		height: 100%;

		path{
			stroke: #fff !important;
			stroke-width: 2px;
			stroke-linejoin: round;
			transition: ease all .3s;
		}

		path.def{
			fill: #d0d0d0 !important;
		}

		path.active{
			fill: #222 !important;

			&:not(.map-view-hide):hover{
				fill: #c71c24 !important;
				cursor: pointer;
			}
		}

		path.previous{
			fill: #777777 !important;

			&:not(.map-view-hide):hover{
				fill: #c71c24 !important;
			}
		}

		// Temporary filtering classes to persist base styles when filter removed
		path.map-view-previous {
			fill: #777777 !important;
		}

		path.map-view-current {
			fill: #222 !important;
		}

		path.map-view-hide {
			fill: #d0d0d0 !important;
		}
	}

	#modal{
		position: fixed;
		display: none;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.1);
		z-index: 9000000;
	}

	#modal-main{
		position: relative;
		margin: 10vh auto auto;
		width: 1100px;
		max-width: 90%;
		background: #fff;
		box-shadow: 0 2px 13px rgba(0, 0, 0, 0.1);
		/* border-radius: 5px; */
		overflow: hidden;
		max-height: 80vh;
	}

	#modal-header{
		position: relative;
		min-height: 40px;
		padding: 40px 30px;
		box-sizing: content-box;
		overflow: hidden;

		p{
			font-size: 2rem;
		}

		select{
			width: 200px;
			height: 38px;
			border: 1px solid #ddd;
		}

		label{
			margin-left: 15px;
			font-size: 2rem;
		}

		#close-modal{
			position: absolute;
			top: 40px;
			right: 30px;
			cursor: pointer;
			transition: all 0.3s;

			svg{
				height: 60px;
				width: 60px;
				float: right;
				margin-top: -20px;
			}
		}
	}

	#modal-list{
		position: relative;
		height: 60vh;
		overflow: scroll;
		padding: 0 30px 40px;
		font-weight: bold;
	}

	.modal-logo{
		padding: 30px 0;
	}

	.modal-item{
		padding-bottom: 45px;
	}

	.modal-link {
		width: 100%;
	}

	.modal-details{
		*{
			font-family: var(--text-font);
			font-weight: bold;
		}

		h1,h2,h3,h4,h5,h6{
			font-size: 2.6rem;
			text-transform: uppercase;
		}
	}

	.tablet({
		#modal-header{
			overflow: hidden;
			> div{
				float: left;
			}

			> div > *{
				float: left;
			}
		}
		.modal-item{
			display: flex;
			justify-content: space-between;
		}

		.modal-link{
			display: flex;
			justify-content: space-between;
		}

		.modal-image{
			width: 55%;
		}

		.modal-details{
			width: 40%;
			font-size: 1.9rem;
			text-transform: uppercase;
		}
	});

	.desktop({
		padding:  200px 20px 150px;
	})
}