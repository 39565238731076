.block.culture {
  padding-left: 32px;
  padding-bottom: 48px;
  position: relative;
  .left-title {
    transform: rotate(-90deg);
    transform-origin: bottom left;
    position: absolute;
    top: 35rem;
    left: 4rem;
    h2,
    h3,
    h4,
    h5 {
      font-size: 1.4rem;
      letter-spacing: 2px;
    }
  }
  .left-title::after {
    content: " ";
    display: block;
    height: 2px;
    background: @heading-alt;
    width: 12rem;
    position: absolute;
    right: -13rem;
    top: calc(50% - 10px);
  }
  &.in-view .left-title,
  &.in-view .text-container .text-wrapper {
    animation-name: cultureFadeIn;
    animation-fill-mode: forwards;
    animation-duration: 0.8s;
    animation-delay: 0.4s;
  }

  .img-container {
    .img-wrapper {
      img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
        margin-bottom: 10px;
      }
    }
  }
  .text-container {
    .text-wrapper {
      padding: 32px 56px 32px 32px;
      background-color: @secondary-bg;
      display: flex;
      flex-direction: column;
      p {
        font-size: 1.8rem;
      }
    }
  }

  .tablet({
			.left-title {
				left: 6rem;
				h2, h3, h4, h5 {
					font-size: 3rem;
				}
			}
			.left-title::after {
				width: 14.5rem;
				right: -16rem;
				top: calc(50% - 10px);
			}
			.img-container {
				position: relative;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
				gap: 0 10px;
				.img-wrapper {
					flex: 1;
					img {
						width: 100%;
						height: auto;
						margin-bottom: 0;
					}
				}
			}
			.text-container {
				display: flex;
				justify-content: end;
				width: 100%;
				padding-right: 20px;
				.text-wrapper {
					display: flex;
					width: 80%;
					justify-content: end;
					padding: 32px 56px 32px 32px;
					background-color: @secondary-bg;
					margin-top: -60px;
					position: relative;
					z-index: 9;
				}
		}
});
}

@keyframes cultureFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
