.block.stats {
  .inner {
    padding: 80px 24px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    width: 100%;
    li {
      width: 100%;
      position: relative;
      padding: 12px;
      transition: all 0.3s;
      opacity: 1;
      span {
        position: relative;
        font-weight: 700;
        font-size: 5rem;
        letter-spacing: 3px;
        width: 100%;
      }
      .plus {
        padding-right: 40px;
      }
      .stat {
        color: @heading-alt;
        font-family: @title;
      }
      &.in-view {
        animation-name: fadeIn;
        animation-fill-mode: forwards;
        animation-duration: 0.8s;
        animation-delay: 0.4s;
        &:nth-child(1) {
          animation-delay: 300ms;
        }
        &:nth-child(2) {
          animation-delay: 400ms;
        }
        &:nth-child(3) {
          animation-delay: 500ms;
        }
      }
      h2,
      h3,
      h4,
      h5 {
        font-size: 2.5rem;
        text-transform: uppercase;
        font-family: @main;
      }
    }

    .plus::after {
      position: absolute;
      right: 0;
      content: "\002B";
      font-weight: 400;
      font-family: @title;
    }
  }

  .tablet({
		ul {
			flex-direction: row;
			justify-content: space-between;
			.large {
				flex: 3;
			}
			.small {
				flex: 1;
			}
	  }
	});
}

// Keyframes
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
