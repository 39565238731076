@import "../base/_utilities";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap");

// universal
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus {
    outline: @button-bg auto 3px;
  }
}

img {
  max-width: 100%;
}

// links
a {
  text-decoration: none;
  transition: ease all 0.3s;
  outline: none;
  color: @text-link;
  font-family: @main;
  font-size: 1.6rem;
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  /* mobile tap color */
  &:link {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  }
}

.block {
  .fontSize(1.6,1.6,1.6);
  // headings
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    text-rendering: optimizelegibility;
    margin: 0;
    font-weight: 700;
    line-height: 1.3;
    font-family: @title;
    color: @heading;
    span,
    li {
      font-size: inherit !important;
      font-family: @title !important;
      text-transform: inherit !important;
      font-weight: 700 !important;
      color: inherit !important;
    }
  }

  h1,
  .h1 {
    .fontSize(3.6,3.6,3.6);
  }

  h2,
  .h2 {
    .fontSize(3,3,3);
    padding-bottom: 15px;
  }

  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    .fontSize(1.8,1.8,1.8);
  }

  .arrow-btn {
    a {
      color: @text;
      font-family: @main;
      font-size: 1.8rem;
      font-weight: 700;
      text-transform: uppercase;
      &::after {
        content: url("/assets/img/red-arrow.png");
        display: inline-block;
        padding-left: 8px;
        transition: transform 0.3s ease-out;
      }
      &:hover::after {
        transform: translateX(4px);
      }
      &::after:hover {
        transform: translateX(4px);
      }
    }
    margin: 16px 0;
    position: relative;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  p,
  li,
  span,
  .text {
    margin: 0;
    color: @text;
    font-weight: 500;
    line-height: 1.5;
    &:not(.ti) {
      font-family: @main;
    }
  }

  .text-alt {
    p,
    span,
    li {
      color: @text-alt !important;
    }
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
      color: @heading-alt;
    }
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
}

button,
.button,
a.button {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 12px 24px;
  background: @button-bg;
  border: 2px solid @button-border;
  color: @button-text;
  font-family: @main;
  font-size: 1.8rem;
  border-radius: 1px;
  font-weight: 700;
  transition: ease all 0.3s;
  text-align: center;
  margin: auto;
  cursor: pointer;
  text-transform: uppercase;
  &.ghost {
    background: none;
    color: @button-bg;
    border: 0;
    border-bottom: 3px solid @button-border;
  }

  &.inline {
    display: inline-block;
  }

  &:hover,
  &:focus {
    background: @button-bg-hover;
  }
}

.outline {
  background: none;
  border: 2px solid blue;
  color: #000;
}

p,
ul,
ol,
dl {
  // + p, + ul, + ol, + dl {
  // 	margin-top: 1.5em;
  // }
}

nav,
.nav,
nav ul,
.nav ul {
  list-style-type: none;
  padding-left: 0;
}

hr {
  border: none;
  background: #eee;
  height: 2px;
  width: 60px;
  margin: 35px auto 0;
}

.alignleft,
.alignright {
  margin-bottom: 1em;
}

address {
  font-style: normal;
}

.center {
  text-align: center;
}

.upper {
  text-transform: uppercase;
}

.screen-reader-text {
  position: absolute !important;
  left: -999em;
}

.html-times {
  font-size: 1.5em;
  vertical-align: sub;
}

.tablet({
});

.desktop({});
