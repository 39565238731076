.block.leadership {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 120px 24px 80px 24px;
  .inner {
    min-height: 600px;
    .left-title {
      transform: rotate(-90deg);
      transform-origin: bottom left;
      position: absolute;
      top: 35rem;
      left: 3rem;
      z-index: 9;
      h2,
      h3,
      h4,
      h5 {
        font-size: 1.4rem;
        letter-spacing: 2px;
      }
    }
    .left-title::after {
      content: " ";
      display: block;
      height: 2px;
      background: @heading-alt;
      width: 12rem;
      position: absolute;
      right: -13rem;
      top: calc(50% - 10px);
    }
    &.in-view .left-title {
      animation-name: leaderFadeIn;
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      animation-delay: 0.6s;
    }
    .our-team {
      margin-left: 3.5rem;
      .card {
        padding: 12px;
        margin-bottom: 32px;
        .img-wrapper {
          height: 400px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            transform: scale(1);
            transition: transform 0.8s ease-in-out;
            cursor: pointer;
          }
          img:hover {
            transform: scale(1.1);
          }
        }
      }
      .text-wrapper {
        position: relative;
        padding: 12px;
        color: @text;
        background-color: @secondary-bg;
        margin-top: -30px;
        margin-left: 6%;
        border: none;
        width: 94%;
        cursor: pointer;
        text-align: center;
        h2,
        h3,
        h4,
        h5 {
          pointer-events: none;
          text-transform: uppercase;
          font-family: @main;
        }
        p {
          font-size: 1.4rem;
          pointer-events: none;
        }
      }
      .text-wrapper.open {
        background-color: @button-bg;
        * {
          color: @button-text;
        }
        color: @button-text;
      }
    }
    .bio-wrapper {
      display: none;
    }
    .mobile-bio.hidden {
      display: none;
    }
    .mobile-bio:not(.hidden) {
      display: block;
      padding-top: 15px;
      color: @text;
      p {
        padding: 8px 0;
      }
      h2,
      h3,
      h4,
      h5 {
        font-size: 2rem;
      }
    }
  }
  .link-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .tablet({
		.inner {
			position: relative;
			display: flex;
			flex-direction: row;
			padding: 0 24px;
			.left-title {
				h2, h3, h4, h5 {
					font-size: 3rem;
				}
			}
			.left-title::after {
				width: 14.5rem;
				right: -16rem;
				top: calc(50% - 10px);
			}
		}
		.our-team {
			display: flex;
			flex-wrap: wrap;
			width: 75%;
      .card {
        flex: 0 0 33.3%;
        .img-wrapper {
          height: 400px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
		.bio-wrapper {
			display: block !important;
			position: relative;
			width: 25%;
			padding: 12px;
			.member-bio {
				position: relative;
				top: 100px;
				span {
          font-family: @main;
				  color: @text;
					font-size: 1.6rem;
					font-weight: 700;
					text-transform: uppercase;
				}
				h2, h3, h4, h5 {
					font-size: 3.5rem;
					color: @heading-alt;
					margin-bottom: 20px;
					padding: 0;
				}
				p {
					margin-bottom: 20px;
				}
			}
		}
	});
}

@keyframes leaderFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
